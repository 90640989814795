import { SelectedResources } from 'bringg-web/features/optimization-wizard/types';

export const isNoResourceSelected = (selectedResourcesIds: SelectedResources) => {
	if (selectedResourcesIds) {
		for (const resourcesByDate of Object.values(selectedResourcesIds)) {
			if (resourcesByDate.selectAllInDate) {
				return false;
			}
			for (const resourceByTeam of Object.values(resourcesByDate.checkboxesByTeam)) {
				if (resourceByTeam.selectAllInTeam) {
					return false;
				}
				const checkedResource = Object.values(resourceByTeam.selectedIds).find(
					selectedResource => selectedResource.isChecked
				);
				if (checkedResource) {
					return false;
				}
			}
		}
	}
	return true;
};
