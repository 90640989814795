import { SentryService } from '@bringg/frontend-utils';
import { IErrorNotifier } from '@bringg/frontend-utils/dist/services/airbrake/error-notifier';
import { getSharedRootStore } from '@bringg-frontend/global-stores';

import { AppConfig } from '../config';
import { getRootEnv } from '../create-root-env';

export class SentryNotifier {
	private notifier!: IErrorNotifier;

	constructor() {
		this.init();
	}

	async init() {
		const envConfig = await AppConfig.getEnvironmentConfig();
		const { environment, sentry_dsn } = envConfig;

		const didSentryAlreadyInstrumentedElsewhere =
			window.firstSentryInitSource !== undefined && window.firstSentryInitSource !== 'react';

		if (sentry_dsn) {
			this.notifier = new SentryService({
				dsn: sentry_dsn,
				environment: environment,
				integrations: didSentryAlreadyInstrumentedElsewhere ? [] : undefined
			});

			window.firstSentryInitSource = window.firstSentryInitSource || 'react';
		} else {
			this.notifier = undefined!;

			window.firstSentryInitSource =
				window.firstSentryInitSource === 'react' ? undefined : window.firstSentryInitSource;
		}
	}

	// for tests
	reset() {
		this.notifier = undefined!;
	}

	public notify(error: Error, params = {}, context = null) {
		if (this.notifier) {
			const { currentUser } = getSharedRootStore().data.usersStore;
			const { environment } = getRootEnv().envConfig;
			const user_id = currentUser?.id;
			const merchant_id = currentUser?.merchant_id;

			this.notifier.notify({
				error,
				params: {
					...params,
					user_id,
					merchant_id,
					environment
				},
				context
			});
		}
	}
}

export const sentryNotifier = new SentryNotifier();
