import React, { ErrorInfo } from 'react';

import { useTranslation } from 'react-i18next';
import { ErrorBoundaryPage } from '@bringg/react-components';

import { airBrakeNotifier } from '../air-brake-notifier/air-brake-notifier';
import { sentryNotifier } from '../sentry-notifier/sentry-notifier';

import './error-boundary.scss';

export const errorNotifier = (error: Error, info: ErrorInfo) => {
	airBrakeNotifier.notify(error, { info });
	sentryNotifier.notify(error, { info });
};

export function withErrorBoundary<P>(Component: React.ComponentType<P>) {
	const Wrapped: React.ComponentType<P> = props => {
		const { t } = useTranslation();

		return (
			<ErrorBoundaryPage
				title={t('ERROR_BOUNDARY_PAGE.TITLE')}
				subtitle={t('ERROR_BOUNDARY_PAGE.SUBTITLE')}
				errorNotifier={errorNotifier}
			>
				<Component {...props} />
			</ErrorBoundaryPage>
		);
	};

	return Wrapped;
}
