import { action, computed, makeObservable, observable } from 'mobx';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import map from 'lodash/map';
import each from 'lodash/each';
import { ApplicationUuid, EntityDataType } from '@bringg/types';
import { getSharedRootStore } from '@bringg-frontend/global-stores';
import { airBrakeNotifier, getRootEnv, sentryNotifier } from '@bringg-frontend/bringg-web-infra';
import { TaskManagedAttributeDataType } from '@bringg/common-utils';

import Webhook from './webhook/webhook';
import { WebhookConfig, WebhookData, WebhookMerchantConfigurationData, WebhookModels } from './webhooks.consts';

export interface ApplicationConfig {
	[key: string]: WebhookConfig;
}

const TASK_MANAGED_ATTRIBUTE_WEBHOOK_MODEL = 'task_managed_attributes';

class WebhooksAppStore {
	private applicationMerchantConfigurationData: WebhookMerchantConfigurationData = {};

	private applicationConfiguration: ApplicationConfig = {};

	private _webhookModels: WebhookModels = [];

	private webhooksData: Map<string, Webhook> = new Map();

	constructor() {
		makeObservable<WebhooksAppStore, 'applicationConfiguration' | '_webhookModels' | 'webhooksData'>(this, {
			applicationConfiguration: observable,
			_webhookModels: observable,
			webhooksData: observable.shallow,
			setWebhook: action,
			removeWebhook: action,
			webhooks: computed,
			applicationConfig: computed,
			isApplicationInstalled: computed,
			webhookModels: computed,
			setApplicationConfiguration: action,
			setWebhookModels: action,
			updateWebhook: action
		});
	}

	setApplicationConfiguration = (applicationConfiguration: ApplicationConfig) => {
		this.applicationConfiguration = applicationConfiguration;
	};

	setWebhookModels = (webhookModels: WebhookModels) => {
		this._webhookModels = webhookModels;
	};

	fetchConfiguration = async (): Promise<void> => {
		const application = getSharedRootStore().data.applicationStore.getApplication(ApplicationUuid.WebhookApp);

		if (isNil(application)) {
			return;
		}

		const { applicationMerchantConfigurationStore } = getSharedRootStore().data;

		await applicationMerchantConfigurationStore.fetchConfiguration(ApplicationUuid.WebhookApp);
		const merchantConfiguration = applicationMerchantConfigurationStore.getConfiguration(
			ApplicationUuid.WebhookApp
		);

		const { configuration } = application;

		await this.enrichConfigurationWithManagedAttributesModels(configuration);

		this.applicationMerchantConfigurationData = merchantConfiguration || {};
		this.setWebhookModels(configuration?.webhook_models);
		this.setApplicationConfiguration(keyBy(configuration && configuration.webhooks, 'key'));
		each(get(merchantConfiguration, 'webhooks', []), webhook => this.setWebhook(new Webhook(webhook, this)));
	};

	updateWebhooks = async (webhooks: WebhookData[]): Promise<void> => {
		await getSharedRootStore().data.applicationMerchantConfigurationStore.updateConfiguration(
			ApplicationUuid.WebhookApp,
			{
				...this.applicationMerchantConfigurationData,
				webhooks
			}
		);
	};

	updateWebhook = async (webhook: Webhook): Promise<void> => {
		const cloneMap = new Map(this.webhooksData);
		cloneMap.set(webhook.uuid, webhook);

		await this.updateWebhooks(map(Array.from(cloneMap.values()), w => w.toJson()));
		this.setWebhook(webhook);
	};

	deleteWebhook = async (uuid: string): Promise<void> => {
		const cloneMap = new Map(this.webhooksData);
		cloneMap.delete(uuid);

		await this.updateWebhooks(map(Array.from(cloneMap.values()), webhook => webhook.toJson()));
		this.removeWebhook(uuid);
	};

	installApp = async (): Promise<void> => {
		await getSharedRootStore().data.applicationStore.installApplication(ApplicationUuid.WebhookApp);
	};

	setWebhook = (webhook: Webhook): void => {
		this.webhooksData.set(webhook.uuid, webhook);
	};

	removeWebhook = (uuid: string): void => {
		this.webhooksData.delete(uuid);
	};

	async enrichConfigurationWithManagedAttributesModels(configuration: any): Promise<void> {
		if (!configuration?.webhook_models) {
			return;
		}
		const webhooksModels: WebhookModels = configuration.webhook_models;

		if (!webhooksModels.some(model => model.name === TASK_MANAGED_ATTRIBUTE_WEBHOOK_MODEL)) {
			webhooksModels.push({
				name: TASK_MANAGED_ATTRIBUTE_WEBHOOK_MODEL,
				allowedFields: [],
				allowedRelations: [],
				defaultFields: [],
				defaultRelations: []
			});
		}

		const managedAttributesModel = webhooksModels.find(model => model.name === 'task_managed_attributes');

		const managedAttributes = await getRootEnv().dashboardSdk.sdk.v2.taskManagedAttributes.getAvailableAttributes();

		const taskAttributes = managedAttributes.attributes.task;

		const nonRelationalFields = taskAttributes.filter(attr => !attr.data_type.entity);
		const relationalFields = taskAttributes.filter(attr => attr.data_type.entity);

		managedAttributesModel.allowedFields = nonRelationalFields.map(attr => attr.identifier);
		managedAttributesModel.allowedRelations = relationalFields
			.map(({ identifier, data_type }) => convertRelationalFieldToValidRelation({ identifier, data_type }))
			.filter(Boolean);
	}

	get webhooks(): Webhook[] {
		return Array.from(this.webhooksData.values());
	}

	get applicationConfig(): ApplicationConfig {
		return this.applicationConfiguration;
	}

	get isApplicationInstalled(): boolean {
		return getSharedRootStore().data.applicationStore.isApplicationInstalled(ApplicationUuid.WebhookApp);
	}

	get webhookModels(): WebhookModels {
		return this._webhookModels;
	}
}

function convertRelationalFieldToValidRelation(attr: {
	identifier: string;
	data_type: TaskManagedAttributeDataType;
}): string {
	switch (attr.data_type.entity) {
		case EntityDataType.Driver:
			return {
				identifier: attr.identifier,
				name: 'user'
			} as any;
		default: {
			airBrakeNotifier.notify(
				new Error(
					`Unsupported relational entity type ${
						(
							attr.data_type as {
								entity: EntityDataType;
							}
						).entity
					}, please add it`
				),
				{
					params: {
						identifier: attr.identifier,
						entity: (attr.data_type as { entity: EntityDataType }).entity
					}
				}
			);

			sentryNotifier.notify(
				new Error(
					`Unsupported relational entity type ${
						(
							attr.data_type as {
								entity: EntityDataType;
							}
						).entity
					}, please add it`
				),
				{
					params: {
						identifier: attr.identifier,
						entity: (attr.data_type as { entity: EntityDataType }).entity
					}
				}
			);
			return null;
		}
	}
}

export default WebhooksAppStore;
