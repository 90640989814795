import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import * as Bringg from '@bringg/types';
import {
	AuthenticationMethod,
	AuthorizationType,
	WebhookAuthenticationConfigurationDto,
	WebhookAuthenticationConfigurationListItemDto
} from '@bringg/types';
import { countBy } from 'lodash';

import { webhooksApiRootStore } from '../webhooks-api-root-store';

class WebhookAuthenticationConfigurationsStore {
	isFetched = false;
	webhookAuthenticationConfigurations = new Map<number, WebhookAuthenticationConfigurationListItemDto>();
	webhookUsagesCountMap: {
		webhooks: Record<number, number>;
		analyticsReports: Record<number, number>;
	} = { webhooks: {}, analyticsReports: {} };

	constructor() {
		makeObservable(this, {
			fetchAll: action,
			getAll: computed,
			getWebhookAuthUsagesCount: computed,
			create: action,
			update: action,
			delete: action,
			getById: action,
			isFetched: observable,
			webhookAuthenticationConfigurations: observable,
			webhookUsagesCountMap: observable
		});
	}

	async create(
		webhookAuthentication: WebhookAuthenticationConfigurationDto
	): Promise<Bringg.WebhookAuthenticationConfigurationDto> {
		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.create(
			webhookAuthentication
		);

		this.webhookAuthenticationConfigurations.set(result.id, {
			...result,
			usage_count: { analytics_reports_scheduled_jobs_count: 0 }
		});

		return result;
	}

	async update(
		id: number,
		webhookAuthentication: Partial<WebhookAuthenticationConfigurationDto>
	): Promise<Bringg.WebhookAuthenticationConfigurationDto> {
		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.update(
			id,
			webhookAuthentication
		);

		this.webhookAuthenticationConfigurations.set(result.id, {
			...result,
			usage_count: this.webhookAuthenticationConfigurations.has(result.id)
				? this.webhookAuthenticationConfigurations.get(result.id).usage_count
				: { analytics_reports_scheduled_jobs_count: 0 }
		});

		return result;
	}

	async fetchAll(isWACAPIOAuthEnabled = false) {
		if (this.isFetched) {
			return;
		}

		const result = await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.list();

		this.webhookAuthenticationConfigurations = new Map(
			result
				.filter(configuration => configuration.type !== AuthorizationType.STATIC_HEADER)
				.filter(
					configuration =>
						isWACAPIOAuthEnabled ||
						!(
							configuration.method === AuthenticationMethod.API &&
							configuration.type === AuthorizationType.OAUTH2
						)
				)
				.map(configuration => [configuration.id, configuration])
		);
		this.isFetched = true;
	}

	get getAll() {
		const all = Array.from(this.webhookAuthenticationConfigurations.values());

		return toJS(all);
	}

	get getWebhookAuthUsagesCount() {
		return toJS(this.webhookUsagesCountMap);
	}

	async getById(id: number): Promise<Bringg.WebhookAuthenticationConfigurationDto> {
		return getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.getById(id);
	}

	async delete(configurationId: number): Promise<void> {
		await getRootEnv().dashboardSdk.sdk.webhookAuthenticationConfiguration.delete(configurationId);

		this.webhookAuthenticationConfigurations.delete(configurationId);
	}

	afterInit() {
		const { webhooksAppStore } = webhooksApiRootStore.getStore();

		// construct object of used WebhookAuths based on Webhooks list
		reaction(
			() => webhooksAppStore.webhooks,
			value => {
				const webhookUsagesCountMap = countBy(value, 'webhookAuthenticationConfigurationId');
				// calculate count of scheduled jobs for each WebhookAuth
				const analyticsReportsScheduledJobsCountMap = this.getAll.reduce<
					Record<WebhookAuthenticationConfigurationListItemDto['id'], number>
				>(
					(memo, configuration) => ({
						...memo,
						[configuration.id]: configuration.usage_count.analytics_reports_scheduled_jobs_count
					}),
					{}
				);
				this.webhookUsagesCountMap = {
					webhooks: webhookUsagesCountMap,
					analyticsReports: analyticsReportsScheduledJobsCountMap
				};

				value.forEach(webhook => {
					const webhookAuthenticationConfiguration = this.webhookAuthenticationConfigurations.get(
						webhook.webhookAuthenticationConfigurationId
					);
					webhook.setWebhookAuthenticationConfiguration(webhookAuthenticationConfiguration);
				});
			}
		);
	}
}

export default WebhookAuthenticationConfigurationsStore;
