import React from 'react';

import { Notification } from '@bringg/react-components';
import { airBrakeNotifier, sentryNotifier } from '@bringg-frontend/bringg-web-infra';

export const filterOptionByTitle = (input, option) => option.title.toLowerCase().includes(input.toLowerCase());

export const dontLoseFocus = (e: React.MouseEvent<HTMLDivElement>) => {
	e.preventDefault();
	e.stopPropagation();
};
export const openErrorNotification = (error: any) => {
	Notification.error({
		message: error.reason?.name,
		description: error.details,
		placement: 'bottomRight'
	});

	console.error(error);
	airBrakeNotifier.notify(error, {});
	sentryNotifier.notify(error, {});
};

export const filterOption = (input: string, option: any) => {
	if ((!option.label && !option.children) || option.options) {
		return false;
	}

	return (option.label || option.children).toLowerCase().includes(input.toLowerCase());
};
