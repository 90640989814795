import {
	OptimizationResponseFailed,
	RouteOptimizationResult
} from '@bringg/dashboard-sdk/dist/RouteOptimization/RouteOptimization.consts';
import { ParkingSpot, Run, Task } from '@bringg/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { Moment } from 'moment-timezone';

import {
	optimizationTimeProps,
	ResourceWithBlockData,
	SelectedResources
} from 'bringg-web/features/optimization-wizard/types';
import { isNoResourceSelected } from './utils';

class OptimizationStore {
	runningProcess: { [key: number]: number } = {};
	isOptimizeNewAndSaveExisting = false;
	tasksByTeam: { [key: string]: Task[] } = {};
	tasksByTeamInTimeFrame: { [key: string]: Task[] } = {};
	presentedResources: ResourceWithBlockData[];
	selectedResourcesIds: SelectedResources;
	resourcesList: ResourceWithBlockData[] = [];
	resourcesByDate: { [key: string]: ResourceWithBlockData[] } = {};
	selectedDate: string;
	optimizationTime: optimizationTimeProps = { startTime: null, endTime: null };
	tasksDatesBetweenSelectedTimeFrame: string[] = [];
	selectedTeamsIds: number[] = [];
	optimizationResults: { [teamId: string]: RouteOptimizationResult | OptimizationResponseFailed } = {};
	optimizationProgressRate = 0;
	routesPlannerOpenByTeamId = 0;
	isResourceNotInDeliveryBlockErrorMode = false;
	runsWithNoMatchBlocks: Run[] = [];
	isBackToSelectResourceStepButtonClicked = false;
	resourceSelectSearchString = '';
	parkingSpotsByTeamId: Map<number, ParkingSpot[]>;
	tasksCountInTimeRange = 0;
	optimizationButtonDisabled = true;
	isResourcesLoading = false;

	constructor() {
		makeObservable(this, {
			setRunningProcess: action,
			isOptimizeNewAndSaveExisting: observable,
			tasksByTeam: observable,
			setTasksByTeam: action,
			tasksByTeamInTimeFrame: observable,
			setTasksByTeamInTimeFrame: action,
			presentedResources: observable,
			setPresentedResources: action,
			selectedResourcesIds: observable,
			setSelectedResourcesIds: action,
			resourcesList: observable,
			setResourcesList: action,
			resourcesByDate: observable,
			setResourcesByDate: action,
			selectedDate: observable,
			setSelectedDate: action,
			optimizationTime: observable,
			setOptimizationTime: action,
			tasksDatesBetweenSelectedTimeFrame: observable,
			setTasksDatesBetweenSelectedTimeFrame: action,
			selectedTeamsIds: observable,
			setSelectedTeamsIds: action,
			clearAllRunningProcess: action,
			optimizationResults: observable,
			setOptimizationResults: action,
			initStore: action,
			optimizationProgressRate: observable,
			setOptimizationProgressRate: action,
			routesPlannerOpenByTeamId: observable,
			closeRoutesPlanner: action,
			isResourceNotInDeliveryBlockErrorMode: observable,
			setIsResourceNotInDeliveryBlockErrorMode: action,
			runsWithNoMatchBlocks: observable,
			setRunsWithNoMatchBlocks: action,
			isBackToSelectResourceStepButtonClicked: observable,
			setIsBackToSelectResourceStepButtonClicked: action,
			tasksCountInTimeRange: observable,
			setTasksCountInTimeRange: action,
			isResourcesLoading: observable,
			setIsResourcesLoading: action,
			optimizationButtonDisabled: observable,
			isOptimizationButtonDisabled: computed
		});
	}

	setRunningProcess(process: { [x: number]: number }) {
		this.runningProcess = { ...this.runningProcess, ...process };
	}

	removeRunningProcess(processUuid: string) {
		const inProgressProcess = { ...this.runningProcess };
		clearInterval(inProgressProcess[processUuid]);
		delete inProgressProcess[processUuid];
		this.runningProcess = inProgressProcess;
	}

	clearAllRunningProcess() {
		Object.values(this.runningProcess).forEach(process => clearInterval(process));
		this.runningProcess = {};
	}

	setIsOptimizeNewAndSaveExisting = (isNewAndSaveExisting: boolean) => {
		this.isOptimizeNewAndSaveExisting = isNewAndSaveExisting;
	};

	orderTaskByTeam = (tasks: Task[]) => {
		const tasksByTeam = {};
		tasks.forEach(task => {
			const teamId = task.team_ids[0];
			if (tasksByTeam[teamId]) {
				tasksByTeam[teamId].push(task);
			} else {
				tasksByTeam[teamId] = [task];
			}
		});
		return tasksByTeam;
	};

	setTasksByTeam = (tasks: Task[]) => {
		const tasksByTeam = this.orderTaskByTeam(tasks);
		this.tasksByTeam = tasksByTeam;
	};

	setTasksByTeamInTimeFrame = (tasks: Task[]) => {
		const tasksByTeam = this.orderTaskByTeam(tasks);
		this.tasksByTeamInTimeFrame = tasksByTeam;
	};

	setPresentedResources = (presentedResources: ResourceWithBlockData[]) => {
		this.presentedResources = presentedResources;
	};

	setSelectedResourcesIds = (selectedResourcesIds: SelectedResources) => {
		this.selectedResourcesIds = { ...selectedResourcesIds };
	};

	setResourcesList = (resourcesList: ResourceWithBlockData[]) => {
		this.resourcesList = resourcesList;
	};

	setResourcesByDate = (resourcesByDate: { [key: string]: ResourceWithBlockData[] }) => {
		this.resourcesByDate = resourcesByDate;
	};

	setSelectedDate = (selectedDate: string) => {
		this.selectedDate = selectedDate;
	};

	setOptimizationTime = (optimizationTime: { startTime: Moment; endTime: Moment }) => {
		this.optimizationTime = optimizationTime;
	};

	setTasksDatesBetweenSelectedTimeFrame = (tasksDatesBetweenSelectedTimeFrame: string[]) => {
		this.tasksDatesBetweenSelectedTimeFrame = tasksDatesBetweenSelectedTimeFrame;
	};

	setSelectedTeamsIds = (selectedTeams: number[]) => {
		this.selectedTeamsIds = selectedTeams;
	};

	setOptimizationResults = (optimizationResults: { [teamId: string]: RouteOptimizationResult }) => {
		this.optimizationResults = optimizationResults;
	};

	setOptimizationProgressRate = (progressRate: number) => {
		this.optimizationProgressRate = progressRate;
	};

	setRoutesPlannerOpenByTeamId = (teamId: number) => {
		this.routesPlannerOpenByTeamId = teamId;
	};

	closeRoutesPlanner = () => {
		this.routesPlannerOpenByTeamId = 0;
	};

	setIsResourceNotInDeliveryBlockErrorMode = (isErrorMode: boolean) => {
		this.isResourceNotInDeliveryBlockErrorMode = isErrorMode;
	};

	setRunsWithNoMatchBlocks = (resources: Run[]) => {
		this.runsWithNoMatchBlocks = resources;
	};

	setIsBackToSelectResourceStepButtonClicked = (isBecButtonClick: boolean) => {
		this.isBackToSelectResourceStepButtonClicked = isBecButtonClick;
	};

	setResourceSelectSearchString = (searchString: string) => {
		this.resourceSelectSearchString = searchString;
	};

	setParkingSpotsByTeamId = (teamId: number, parkingSpots: ParkingSpot[]) => {
		if (!this.parkingSpotsByTeamId) {
			this.parkingSpotsByTeamId = new Map<number, ParkingSpot[]>();
		}
		this.parkingSpotsByTeamId.set(teamId, parkingSpots);
	};

	setTasksCountInTimeRange = tasksCount => {
		this.tasksCountInTimeRange = tasksCount;
	};

	setIsResourcesLoading = (isLoading: boolean) => {
		this.isResourcesLoading = isLoading;
	};

	get isOptimizationButtonDisabled() {
		return (
			this.isResourcesLoading ||
			!this.tasksCountInTimeRange ||
			!this.resourcesList.length ||
			isNoResourceSelected(this.selectedResourcesIds)
		);
	}

	initStore = () => {
		this.tasksByTeam = {};
		this.selectedResourcesIds = {};
		this.resourcesList = [];
		this.selectedDate = '';
		this.optimizationTime = { startTime: null, endTime: null };
		this.presentedResources = [];
		this.resourcesByDate = {};
		this.selectedTeamsIds = [];
		this.optimizationResults = {};
		this.tasksDatesBetweenSelectedTimeFrame = [];
		this.optimizationProgressRate = 0;
		this.routesPlannerOpenByTeamId = 0;
		this.isResourceNotInDeliveryBlockErrorMode = false;
		this.runsWithNoMatchBlocks = [];
		this.isBackToSelectResourceStepButtonClicked = false;
		this.resourceSelectSearchString = '';
		this.parkingSpotsByTeamId = new Map<number, ParkingSpot[]>();
	};
}

export default OptimizationStore;
