export function filterObject<Obj extends object>(obj: Obj, filterFn: (value, key) => boolean): Obj {
	return Object.entries(obj).reduce((cleanObject, [key, value]) => {
		if (filterFn(value, key)) {
			cleanObject[key] = value;
		}

		return cleanObject;
	}, {} as Obj);
}

export function objectDiff(obj1: Record<string, unknown>, obj2: Record<string, unknown>): Record<string, unknown> {
	if (obj1 === null) {
		return obj2;
	}

	return Object.keys(obj2).reduce((result, key) => {
		if (obj2[key] !== obj1[key]) {
			if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
				const nestedDiff = objectDiff(
					obj1[key] as Record<string, unknown>,
					obj2[key] as Record<string, unknown>
				);
				if (Object.keys(nestedDiff).length > 0) {
					result[key] = nestedDiff;
				}
			} else {
				result[key] = obj2[key];
			}
		}

		return result;
	}, {});
}

export function flattenObject(obj: Record<string, any>) {
	const toReturn = {};

	for (const key of Object.keys(obj)) {
		if (obj[key] === null || typeof obj[key] !== 'object') {
			toReturn[key] = obj[key];
		} else {
			const flatObject = flattenObject(obj[key]);

			for (const flatObjectKey of Object.keys(flatObject)) {
				toReturn[key + '.' + flatObjectKey] = flatObject[flatObjectKey];
			}
		}
	}
	return toReturn;
}

export const isObjectLike = (value: unknown): value is Record<string, unknown> =>
	typeof value === 'object' && value !== null && !Array.isArray(value);
