import React, { useEffect, useState } from 'react';

import { DateDisplay, Spinner, Table } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import { observer, Observer } from 'mobx-react';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import type { Task as TaskType } from '@bringg/types';
import { getUpToNFractionDigitsFromNumber } from '@bringg-frontend/utils';

import { isInternalFleet } from 'bringg-web/stores/fleets/fleet-checker';
import { useStores } from '../../recipes';
import TaskRelatedFleetStore from '../../stores/task-related-fleet/task-related-fleet-store';

import './fleet-assigner-table.scss';

export interface FleetAssignerTableProps {
	selectedFleetId: TaskRelatedFleetStore['id'];
	setSelectedFleetId: (selectedFleetId: TaskRelatedFleetStore['id']) => unknown;
	fleets: TaskRelatedFleetStore[];
	timezone: string;
	gridSelectedTasks?: TaskType[];
	shouldTranslateInternalFleetName?: boolean;
}

const NUMBER_OF_MAXIMUM_DIGITS_IN_FEE = 3;

// antd having problem receiving observed component (using observer(() => <some component>)) so we wrapped it
const createObservedRenderFunction =
	cb =>
	(...args) =>
		<Observer>{() => cb(...args)}</Observer>;

const FleetAssignerTable = ({
	fleets,
	selectedFleetId,
	setSelectedFleetId,
	timezone,
	gridSelectedTasks,
	shouldTranslateInternalFleetName
}: FleetAssignerTableProps) => {
	const { t } = useTranslation();
	const { merchantConfigurationsStore } = useStores();
	const [sortedFleets, setSortedFleets] = useState<TaskRelatedFleetStore[]>([]);

	const sortFleets = fleetsToSort => {
		return [...fleetsToSort].sort((fleetA, fleetB) => {
			if (fleetA.is_capable_by_delivery_term && !fleetB.is_capable_by_delivery_term) {
				return -1;
			}

			if (!fleetA.is_capable_by_delivery_term && fleetB.is_capable_by_delivery_term) {
				return 1;
			}

			if (fleetA.capabilities?.is_green && !fleetB.capabilities?.is_green) {
				return -1;
			}

			if (!fleetA.capabilities?.is_green && fleetB.capabilities?.is_green) {
				return 1;
			}

			return fleetA.name.toString().localeCompare(fleetB.name.toString());
		});
	};

	useEffect(() => {
		const _sortedFleets = sortFleets(fleets);

		setSortedFleets(_sortedFleets);
	}, [fleets]);

	const columnsList = [
		{
			title: t('ASSIGN_FLEET.DELIVERY_PROVIDER_COLUMN'),
			key: 'name',
			render: createObservedRenderFunction((fleet: TaskRelatedFleetStore) => {
				const internalFleetName = shouldTranslateInternalFleetName
					? t('ASSIGN_FLEET.INTERNAL_FLEET_TEXT')
					: fleet.name;
				return (
					<span className="fleet-name" data-test-id="fleet-name">
						{isInternalFleet(fleet.id, fleet.fleet_type) ? internalFleetName : fleet.name}
					</span>
				);
			})
		},
		{
			title: t('ASSIGN_FLEET.ETA'),
			key: 'eta',

			render: createObservedRenderFunction((fleet: TaskRelatedFleetStore) => {
				const formatEta = (eta: string) => {
					if (!eta) {
						return t('ASSIGN_FLEET.NOT_AVAILABLE');
					}

					return (
						<DateDisplay
							date={eta}
							format={merchantConfigurationsStore.hourStringFormat}
							timezone={timezone}
						/>
					);
				};

				return (
					<span className="center-in-cell eta" data-test-id="fleet-eta">
						{fleet.is_quote_data_fetched ? (
							formatEta(fleet.pickup_date)
						) : (
							<Spinner size="small" className="fleet-assigner-table-spinner" />
						)}
					</span>
				);
			})
		},
		{
			title: t('ASSIGN_FLEET.DELIVERY_TERMS_COLUMN'),
			key: 'isFleetCapable',

			render: createObservedRenderFunction((fleet: TaskRelatedFleetStore) => {
				return (
					<span className="center-in-cell" data-test-id="fleet-is-capable-by-delivery-term">
						{_isNil(fleet.is_capable_by_delivery_term) ? (
							'-'
						) : (
							<BringgIcon
								iconName={
									fleet.is_capable_by_delivery_term ? BringgFontIcons.Selected : BringgFontIcons.Close
								}
								className={
									fleet.is_capable_by_delivery_term ? 'fleet-icon-capable' : 'fleet-icon-incapable'
								}
							/>
						)}
					</span>
				);
			})
		},
		{
			title: t('ASSIGN_FLEET.COST_COLUMN'),
			key: 'cost',

			render: createObservedRenderFunction((fleet: TaskRelatedFleetStore) => {
				const formatFee = (fee: number | undefined): string => {
					if (fee === undefined) {
						return '-';
					}
					return getUpToNFractionDigitsFromNumber(fee || 0, NUMBER_OF_MAXIMUM_DIGITS_IN_FEE);
				};
				return (
					<span className="center-in-cell" data-test-id="fleet-cost">
						{fleet.is_quote_data_fetched ? (
							formatFee(fleet.fee)
						) : (
							<Spinner
								className="fleet-assigner-table-spinner"
								size="small"
								data-test-id={`fleet-assigner-table-cost-${fleet.id}`}
							/>
						)}
					</span>
				);
			})
		},
		{
			title: t('ASSIGN_FLEET.ECO_COLUMN'),
			key: 'eco',
			render: createObservedRenderFunction((fleet: TaskRelatedFleetStore) => (
				<span className="center-in-cell">
					{fleet.capabilities?.is_green && (
						<BringgIcon
							iconName={BringgFontIcons.Eco}
							data-test-id="fleet-eco"
							className="fleet-eco-icon"
						/>
					)}
				</span>
			))
		}
	];

	const getTableColumns = () => {
		const shouldSkipEta = gridSelectedTasks && gridSelectedTasks.length;
		const filteredColumns = columnsList.filter(column => {
			if (column.key === 'eta' && shouldSkipEta) {
				return false;
			}
			return column;
		});

		return filteredColumns;
	};

	return (
		<div className="fleets-table">
			<Table
				rowKey="id"
				scroll={{ y: 400 }}
				columns={getTableColumns()}
				rowSelection={{
					selectedRowKeys: selectedFleetId ? [selectedFleetId] : [],
					type: 'radio'
				}}
				dataSource={sortedFleets}
				onRow={fleet => ({
					onClick: () => setSelectedFleetId(fleet.id)
				})}
			/>
		</div>
	);
};

export default observer(FleetAssignerTable);
