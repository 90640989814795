import {
	CustomAttributeDataType,
	CustomAttributeRes,
	ManualTriggerInputParam,
	PrimitiveManualTriggerInputParam,
	PrimitiveManualTriggerParamDataType,
	ReferredManualTriggerInputParam,
	ReferredManualTriggerParamDataType
} from '@bringg/types';
import { EnumValues } from 'enum-values';
import { airBrakeNotifier, sentryNotifier } from '@bringg-frontend/bringg-web-infra';

export const PRIMITIVE_DATA_TYPES = EnumValues.getValues<PrimitiveManualTriggerParamDataType>(
	PrimitiveManualTriggerParamDataType
);

export function isPrimitiveManualTriggerInputParam(
	param: ManualTriggerInputParam
): param is PrimitiveManualTriggerInputParam {
	return PRIMITIVE_DATA_TYPES.includes(param.data_type as PrimitiveManualTriggerParamDataType);
}

export const REFERRED_DATA_TYPES = EnumValues.getValues<ReferredManualTriggerParamDataType>(
	ReferredManualTriggerParamDataType
);

const SEPARATOR = crypto.randomUUID();

type ReferredDatatypeData = { dataType: ReferredManualTriggerParamDataType; refId: number };

export type EncodedDataType = string;

export function encodeReferredParam(param: ReferredDatatypeData): EncodedDataType {
	return `${param.dataType}${SEPARATOR}${param.refId}`;
}

export function decodeParam(encoded: EncodedDataType): ReferredDatatypeData {
	const logPrefix = 'automation-workflows: decodeParam:';
	const split = encoded.split(SEPARATOR);

	const [dataType, refIdAsString] = split as [ReferredManualTriggerParamDataType, string];
	if (split.length === 1) {
		return { dataType, refId: undefined };
	}

	const refId = parseInt(refIdAsString);
	if (!REFERRED_DATA_TYPES.includes(dataType) || _.isNaN(refId)) {
		airBrakeNotifier.notify(new Error(`${logPrefix} decoding resulted in bad result`), {
			encoded
		});
		sentryNotifier.notify(new Error(`${logPrefix} decoding resulted in bad result`), {
			encoded
		});
		return;
	}

	return { dataType, refId };
}

export const SUPPORTED_CUSTOM_ATTRIBUTES_DATA_TYPES = [CustomAttributeDataType.Enum];

export function toReferredManualTriggerInputParam(items: CustomAttributeRes[]): ReferredManualTriggerInputParam[] {
	return items
		.filter(item => SUPPORTED_CUSTOM_ATTRIBUTES_DATA_TYPES.includes(item.data_type))
		.map(customAttribute => ({
			name: customAttribute.name,
			ref_id: customAttribute.id,
			data_type: ReferredManualTriggerParamDataType.CUSTOM_ATTRIBUTE
		}));
}

export function toCustomAttributeDataTypeNameWithPrefix(name) {
	return `Custom Attribute -> ${name}`;
}
